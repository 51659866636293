.trail-info {
  margin-bottom: 80px;
  .add-overlay-btn {
    width: 100%;
    margin-bottom: 20px;
    margin-left: 5px;
    margin-right: 5px;
  }
  .overlay-image {
    font-size: 14px;
    .overlay, .overlay-placeholder {
      width: 100%;
      height: 212px;
      border-radius: 4px;
      margin-bottom: 8px;
    }
    .overlay-placeholder {
      background-color: rgba(101, 26, 152, 0.35);
    }
  }
}
