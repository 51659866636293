.array-field-container {
  display: flex;
  .form-group {
    display: flex;
    width: 100%;
    .form-control {
      height: 100%;
      &.with-remove-btn {
        border-radius: 0.188rem 0 0 0.188rem;
      }
    }
    .remove-btn {
      border-radius: 0 0.188rem 0.188rem 0;
    }
  }
}