@import-normalize;

@import "style/vars";
@import "style/buttons";

@import "node_modules/bootstrap/scss/bootstrap.scss";
@import "node_modules/react-datepicker/src/stylesheets/datepicker.scss";

.input-group-prepend {
  .input-group-text {
    border-right-width: 0;
    padding: 0.375rem 0.25rem 0.375rem 0.625rem;
  }

  & + .form-control {
    border-left-width: 0;
    padding-left: 0.375rem;
  }
}

.btn-primary {
  color: $purple;
  box-shadow: inset 0 -1px 1px 0 rgba(67, 90, 111, 0.2),
    0 2px 4px 0 rgba(102, 120, 138, 0.16);

  &:not(:disabled):not(.disabled) {
    &:hover,
    &:active,
    &:focus {
      color: $purple;
    }
  }

  &.disabled,
  &:disabled {
    color: $purple;
  }
}

.btn-purple {
  color: $purple;
  border: 1px solid $purple;

  &:not(:disabled):not(.disabled) {
    &:hover,
    &:active,
    &:focus {
      color: $purple;
    }
  }
}

.btn-sm {
  font-weight: 500;
}

.btn-outline-light {
  color: $default-color;
}

.pagination-sm .page-link {
  font-size: 0.688rem;
  line-height: 1.2;
  font-weight: 600;
  border-radius: 0.2727em;
}

.form-label {
  color: #8b8b8b;
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 0.33px;
  line-height: 1.333;
  margin-bottom: 4px;
  text-transform: uppercase;
}

.form-control {
  padding-bottom: 0.563rem;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Barlow", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.placeholder {
  color: rgba(139, 139, 139, 0.5);

  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 17px;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  @extend .placeholder;
  opacity: 1; /* Firefox */
}
