.sign-in {
  background: url("./bg-sign-in.jpg");
  background-size: cover;

  .header {
    display: flex;
    align-items: flex-end;
    margin-bottom: 30px;

    .title {
      margin-right: auto;
    }

    .or-sign-up {
      padding-bottom: 2px;

      .or {
        color: #8b8b8b;
        font-weight: 500;
        line-height: 19px;
      }

      .sign-up-link {
        color: #651a98;
        font-weight: bold;
        line-height: 19px;
        text-align: center;
        text-decoration: underline;
        margin-left: 5px;
      }
    }
  }

  .forgot-password-link {
    display: block;
    max-width: 0 auto;
    margin-top: 24px;

    color: #651a98;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    text-align: center;
  }
}
