.checkout-table {
  .title {
    text-align: left !important;
  }
}
.paypal-btns-container {
  display: flex;
  justify-content: flex-end;
}
.paypal-buttons {
  width: 280px !important;
}