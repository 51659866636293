// global styles
.button-icon {
  width: 12px;
  height: 12px;
  margin-right: 5px;
}

.font-weight-500 {
  font-weight: 500 !important;
}

// app component styles
.app-content {
  padding-top: 72px !important;
}
