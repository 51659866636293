.file-control {
  @import "../../../../style/vars";
  .file-control-input {
    font-size: $input-font-size;
    &:after {
      color: #8b8b8b;
      font-size: 12px;
      font-weight: bold;
      background: #ffffff linear-gradient(0deg, #f4f5f7 0%, #ffffff 100%);
      height: auto;
      padding-top: 7px;
    }
  }
  &.fallback-font {
    font-family: Arial, sans-serif;
  }
}
