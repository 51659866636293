.event-tab-content-layout {
  .go-back-link{
    color: #000000;
    margin-right: 20px;
  }
  .header {
    padding-top: 24px;
    padding-bottom: 26px;
  }
}
