.checkbox-control {
  .checkbox-input {
    margin-top: 8px;
  }
  .checkbox-label {
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
  }
}
