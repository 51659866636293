.side-nav {
  .side-nav-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 7px 12px 7px 17px;
    margin-bottom: 16px;
    color: #302A2A;
    border-radius: 4px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 0;
    &.active {
      position: relative;
      color: #651a98;
      background-color: #f8f7fc;
    }
  }
}
