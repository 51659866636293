.image-column {
  margin-left: 20px;
}
.image-preview-container, .react-transform-wrapper {
  height: 515px !important;
  width: 100% !important;
}
.image-preview-container {
  background-position: center;
  background-repeat: no-repeat;
  transition: background 300ms ease-in;
  overflow: auto;
  cursor: move;
  &::-webkit-scrollbar {
    width: 6px;
    height: 5px;
  }
  &::-webkit-scrollbar-thumb {
    border-right: 1px solid rgba(255, 255, 255, 1);
    background: #DFDCE1;
    border-radius: 10px;
  }
  .image-preview {
    position: relative;
  }
}
.no-preview {
  height: 0;
}
.file-control {
  width: 100%;
}
.step {
  color: #651a98;
  font-size: 18px;
  font-weight: 700;
}
.overlay-modal-footer {
  justify-content: space-between;
  padding: 16px;
  & > * {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.cropper-container {
  position: absolute;
  top: -30000px;
  left: -30000px;
}
