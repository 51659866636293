.btn-rounded {
  border-radius: 10px !important;
  &:hover:not(:disabled) svg {
    fill: $white;
  }
}
.btn-round {
  border-radius: 50% !important;
}
.btn-wide {
  padding: 12px 82px !important;
}
.btn-mid {
  max-width: 120px;
  max-height: 40px;
}
.btn-small {
  max-width: 100px;
  max-height: 40px;
}
