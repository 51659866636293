.slider {
  &-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 48px;
    margin-top: 48px;
  }
  &-left-border, &-right-border {
    all: unset;
    & > input {
      max-width: 30px;
      background: #ffffff;
      border: 1px solid #868080;
      border-radius: 10px;
      text-align: center;
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      &:focus-visible, &:focus {
        outline: none;
      }
    }
  }
  &-inner-container {
    width: 100%;
    .age-groups-slider {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 30px;
    }
    .add-btn-container {
      position: absolute;
      display: none;
      align-items: center;
      justify-content: center;
      flex-flow: column nowrap;
      visibility: hidden;
      transition: 300ms opacity, visibility;
      z-index: 2;
      &.show {
        transition-delay: 500ms;
        visibility: visible;
      }
      &.hide {
        animation: hide-btn 400ms ease-in-out forwards;
      }
      .add-btn-label-wrapper {
        position: relative;
        left: 36px;
        .add-btn-label {
          position: relative;
          padding: 8px 12px;
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
          color: rgba(101, 26, 152, 0.4);
          background: rgba(237, 224, 245, 0.53);
          border-radius: 10px;
        }
        .label-pointer {
          position: absolute;
          top: 29px;
          left: 10px;
        }
      }
      .add-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4.5px 5px;
        background: #EDE0F5;
        border: 1px solid #651A98;
        border-radius: 50%;
        &:focus, &:focus-within {
          outline: none;
        }
      }
    }
  }
  @keyframes hide-btn {
    0% {
      opacity: 1;
    }
    70% {
      opacity: 0;
    }
    100% {
      width: 0;
      height: 0;
      padding: 0;
      border-width: 0;
      display: none;
    }
  }
}
