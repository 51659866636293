.mapboxgl-canvas {
  cursor: default;
}
.overlay-map {
  width: 100% !important;
  height: 600px !important;
  &.show {
    animation: show 200ms ease-in forwards;
    @keyframes show {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }
  .overlay-controls {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    padding: 20px 10px;
    background: #FFFFFF;
    border: 1px solid rgba(48, 42, 42, 0.25);
    .control-label {
      color: #302A2A;
      font-size: 14px;
      font-weight: 700;
    }
    .overlay-opacity-col {
      display: flex;
      align-items: center;
    }
    .opacity-slider {
      position: relative;
      width: 100%;
      height: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 9px;
      cursor: pointer;
      background-color: #DFDCE1;
      .thumb {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        width: 30px;
        background: #FFFFFF;
        border-radius: 50%;
        border: 1px solid #651a98;
        cursor: default;
        outline: none;
        .prop-value {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }
  .markers-info {
    position: absolute;
    bottom: 0;
    left: 0;
    min-width: 100%;
    padding: 7px 8px;
    overflow: hidden;
    z-index: 10;
    &.no-coordinates {
      justify-content: flex-end;
      border: none;
      background: transparent;
    }
    .map-control-col {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .icon-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 9px;
      background: #ffffff;
      border-radius: 50%;
      box-shadow: 0 3px 7px rgba(0, 0, 0, 0.331239);
      border: none;
      outline: none !important;
      &.satellite {
        background: #651a98;
      }
    }
    .coordinate-container {
      padding: 4px;
    }
    .coordinate {
      padding-left: 8px;
      font-size: 8px;
    }
  }
}
