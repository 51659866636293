$default-color: #121212;
$yellow: #f8c540;
$purple: #651a98;
$white: #ffffff;
$grey: #edf0f2;

$theme-colors: (
  "primary": $yellow,
  "secondary": #0788de,
  "danger": #ff0000,
  "default": $purple,
  "transparent": rgba(139, 139, 139, 0),
  "light": rgba(139, 139, 139, 0.5),
  "purple": rgba(101, 26, 152, 0.16),
);

$headings-font-weight: bold;
$h2-font-size: 2.25rem;

$body-color: $default-color;
$navbar-light-color: $default-color;
$navbar-light-active-color: $purple;

$btn-padding-x-sm: 1.125rem;
$btn-padding-y-sm: 0.5rem;
$btn-font-size-sm: 0.75rem;
$btn-line-height-sm: 1.16;

$headings-margin-bottom: 0;

$pagination-color: $purple;
$pagination-hover-color: $pagination-color;
$pagination-active-bg: $purple;
$pagination-focus-box-shadow: 0 0 0 0.2rem rgba(101, 26, 152, 0.25);
$pagination-border-width: 0;
$pagination-padding-x-sm: 0.625rem;
$pagination-padding-y-sm: 0.438rem;

$input-border-radius: 0.188rem;
$input-font-size: 0.875rem;
$input-color: $default-color;
$input-font-weight: 500;
$input-line-height: 1.5;
$input-padding-y: 0.375rem;
$input-padding-x: 0.75rem;
$input-bg: $white;
$input-group-addon-bg: $input-bg;
$input-border-color: rgba(67, 90, 111, 0.3);
$input-placeholder-color: rgba(139, 139, 139, 0.5);

$form-group-margin-bottom: 1.25rem;
