.event-header {
  background-color: #651a98;
  //background: url("../../../shared/bg-main.jpg");
  background-size: cover;
  background-position: center;

  .header {
    padding: 32px 10px 40px 0;

    .back-icon {
      color: #ffffff;
      height: 14px;
      width: 16px;
      margin-left: -16px;
      margin-right: 16px;
    }

    .title {
      color: #ffffff;
    }
  }
  .preview-btn {
    background: #2688d1;
    border-radius: 30px;
  }
  .cart-btn {
    position: relative;
    .items-count {
      position: absolute;
      border-radius: 50%;
      background: #d61c6a;
      &.one-digit {
        top: 0;
        right: 10px;
        padding: 1px 5px;
      }
      &.two-digit {
        top: -5px;
        right: 5px;
        padding: 4px 5px;
      }
      &.three-digit {
        top: -10px;
        right: -3px;
        padding: 7px 5px;
      }
    }
  }
}
