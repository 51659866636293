.app-placeholder {
  background: linear-gradient(
    180deg,
    rgba(252, 252, 254, 0.56) 0%,
    #ffffff 99.92%
  );
  height: 100%;

  .title {
    text-align: center;
    color: #651a98;
  }

  .details {
    text-align: center;
  }
}
