.app-tabs {
  display: flex;

  .app-tab {
    flex: 1 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .active-mark {
      border-radius: 10px;
      border-bottom: 3px solid #f8c540;
      visibility: hidden;
    }

    .app-tab-link {
      padding: 5px 0 12px;
      line-height: 1;
      align-self: center;
      color: #ffffff;
      opacity: 0.7;

      &.active:not(.disabled) {
        font-weight: bold;
        opacity: 1;

        + .active-mark {
          visibility: visible;
        }
      }

      .disabled {
        cursor: pointer;
      }
    }
  }
}
