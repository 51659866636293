.slider-track {
  position: relative;
  top: 12px;
  bottom: 0;
  height: 6px;
  z-index: 0;
  cursor: pointer;
}
.remove-range-tooltip {
  text-align: center;
  &.show {
    opacity: 1;
  }
  .tooltip-inner {
    all: unset;
  }
  .remove-btn {
    all: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 16px 5px 18px;
    font-family: Barlow;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    border-radius: 15px;
    color: #FFFFFF;
    cursor: pointer;
    &-icon {
      margin-right: 10px;
    }
  }
}
