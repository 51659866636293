.auth-layout {
  min-height: 100vh;
  margin-top: 88px;

  background-color: #000000;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #23003b 100%);
  padding-top: 124px;

  .auth-layout-frame {
    max-width: 600px;
    padding: 80px 20px;
    margin: 0 auto;

    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 8px 24px 0 rgba(101, 26, 152, 0.16);

    .form-container {
      margin: 0 auto;
      max-width: 280px;
    }
  }

  .form-group {
    margin-bottom: 1rem;
  }
}
