.event-overview {
  margin-bottom: 80px;

  .header-image-preview {
    height: 212px;
  }

  .preview-column {
    display: flex;

    .preview.empty {
      flex: 1;
      background-image: url("./bg-overview-placeholder.png");
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
}
