.help {
  padding-top: 88px;

  .header {
    background-color: #651a98;
    background: url("../../shared/bg-secondary.jpg");
    background-size: cover;
    background-position: center bottom;
    color: #ffffff;

    padding-top: 32px;
    padding-bottom: 8px;

    padding-bottom: 48px;
  }

  .content {
    margin-top: 72px;

    .content-title {
      margin-bottom: 32px;
    }

    section h5 {
      padding-bottom: 24px;
    }
    section p {
      margin-bottom: 24px;
    }
  }
}
