@import "../../style/vars";

.paginated-table {
  .table-responsive {
    &::-webkit-scrollbar {
      height: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $grey;
      border-radius: 10px;
    }
  }
  .table {
    margin-bottom: 0;
    thead {
      background-color: $purple;
      box-shadow: inset 0 -1px 0 0 rgba(67, 90, 111, 0.09);
    }

    th,
    td {
      border: 0;
      padding: 10px;
      white-space: pre;
      vertical-align: middle;
      text-align: center;

      &:first-child {
        padding-left: 25px;
      }

      &:last-child {
        padding-right: 25px;
      }
    }

    th {
      color: #ffffff;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 1.33;
    }

    td {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 16px;
      padding-top: 18px;
      padding-bottom: 20px;
    }

    tbody tr {
      box-shadow: inset 0 -1px 0 0 $grey;
    }

    .tr-placeholder {
      color: inherit;
      background-color: inherit;
      box-shadow: none;
    }
    .gender {
      text-transform: capitalize;
    }
  }

  tr.selectable {
    cursor: pointer;
  }

  .table-pagination {
    margin-top: 24px;
    margin-right: 25px;
  }
}
