.my-events {
  position: relative;

  .background {
    position: absolute;
    z-index: -1;

    height: 400px;
    width: 100%;
    background-color: #651a98;
    background: url("../../shared/bg-main.jpg");
    background-size: cover;
  }

  .welcome-message {
    color: #ffffff;
    font-size: 36px;
    letter-spacing: -0.13px;
    line-height: 43px;

    padding: 32px 16px 24px;
  }

  .event-list {
    border-radius: 4px;
    background-color: #ffffff;

    .list-toolbar {
      padding: 25px 16px 24px;

      .list-title {
        font-size: 24px;
        font-weight: bold;
        letter-spacing: -0.09px;
        line-height: 29px;
        flex: 1 0;
      }

      .tools {
        flex: 0.53 0;
        display: flex;
        justify-content: flex-end;

        .search {
          flex: 1 0;
        }
      }
    }

    .search-input-icon {
      width: 12px;
      height: 12px;
      color: #651a98;
    }
  }
}
