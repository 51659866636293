.check-points {
  margin-bottom: 80px;

  .array-item {
    border-radius: 4px;
    box-shadow: 0 4px 8px 0 rgba(101, 26, 152, 0.16);
    padding: 9px 9px 7px 9px;
    margin-bottom: 4px;

    .delete-item {
      font-weight: normal;
      height: 35px;
    }

    .dd-handle {
      color: #8b8b8b;
    }
  }

  .add-button {
    border-radius: 4px;
    padding: 28px;
    margin-top: 28px;
  }
}
