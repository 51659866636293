.slider-thumb {
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
  cursor: pointer;
  z-index: 2 !important;
  outline: none !important;
  &.hidden {
    display: none;
  }
  &-inner {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-label {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    text-align: right;
    &.empty {
      margin-bottom: 19px;
    }
  }
  .prev-range-border, .next-range-border {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    padding: 7px 5px;
  }
  .prev-range-border {
    border-radius: 10px 0 0 10px;
  }
  .next-range-border {
    border-radius: 0 10px 10px 0;
  }
}
