.image-control {
  .image-preview {
    width: 100%;
    border-radius: 4px;
    background-color: rgba(101, 26, 152, 0.35);
    margin-bottom: 8px;

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .upload-button {
    color: #8b8b8b;
  }
}
