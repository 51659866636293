.sign-up {
  background: url("./bg-sign-up.jpg");
  background-size: cover;

  .header {
    display: flex;
    align-items: flex-end;
    margin-bottom: 20px;

    .title {
      margin-right: auto;
    }

    .or-sign-in {
      padding-bottom: 2px;

      .or {
        color: #8b8b8b;
        font-weight: 500;
        line-height: 19px;
      }

      .sign-in-link {
        color: #651a98;
        font-weight: bold;
        line-height: 19px;
        text-align: center;
        text-decoration: underline;
        margin-left: 5px;
      }
    }
  }

  .terms-link {
    color: #651a98;
    font-weight: 500;
    line-height: 14px;
    margin-left: 5px;
  }
}
