.loader-modal {
  .content {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
  }
  .spinner {
    width: 120px;
    height: 120px;
    border-width: 5px !important;
  }
}