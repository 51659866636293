@import "../../../style/vars";
.results {
  .status-finished {
    color: #47b881;
  }
  .status-error {
    color: #ff0000;
  }
}
.square-btn {
  max-width: 40px;
  max-height: 40px;
  padding: 12px;
}
.add-gpx-btn {
  padding: 12px 19px;
  white-space: pre;
}
.notification-check {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0;
}
.notification-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px;
  margin-right: 10px;
  background: $white;
  border: 1px solid #dfdce1;
  border-radius: 5px;
  transition: background-color 0.25s;
  &.checked {
    background: $purple;
    padding: 5px;
  }
}
.notification-label {
  font-size: 16px !important;
  font-weight: 400 !important;
}
