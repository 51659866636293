.age-groups {
  .header {
    padding-top: 40px;
    padding-bottom: 50px;
    h4 {
      font-size: 32px;
      font-weight: 700;
      line-height: 38px;
      color: #302A2A;
    }
  }
  .slider-tabs {
    .nav-item {
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;
      text-align: right;
      color: #651A98;
      &.active {
        color: #651A98;
        font-weight: 700;
      }
    }
  }
  .top-block {
    margin-bottom: 48px;
  }
  .tab-content {
    padding: 28px 32px 82px;
    background: #FFFFFF;
    box-shadow: 0 1px 9px rgba(44, 13, 65, 0.1);
    margin-bottom: 32px;
  }
  .actions-block {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-flow: column nowrap;
    .export-import-block {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-flow: row nowrap;
    }
  }
  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    &:focus, &:focus-visible {
      outline: none;
    }
    .btn-icon {
      margin-right: 14px;
    }
  }
  .export-btn, .import-btn {
    background: #FFFFFF;
    color: #651A98;
    border: 1px solid #651A98;
    padding: 9px 16px 9px 20px;
    &:disabled {
      opacity: 0.5;
    }
  }
  .export-btn {
    margin-right: 20px;
  }
  .add-group-btn {
    background: #651A98;
    color: #FFFFFF;
    border: 1px solid #651A98;
    padding: 11px 16px 11px 20px;
    margin-bottom: 32px;
  }
  .age-group-tab {
    text-transform: capitalize;
    &.active {
      border: none !important;
      box-shadow: 0 -6px 9px rgba(44, 13, 65, 0.1);
    }
  }
  .nav-tabs {
    border-bottom: none;
  }
  .age-group-container {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    margin-bottom: 32px;
    padding: 2px 0;
    overflow-x: auto;
    .order {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 50px;
      padding: 44px 0;
      border-radius: 10px 0 0 10px;
      color: #ffffff;
    }
    .age-group {
      width: 100%;
      background: #FFFFFF;
      box-shadow: 0 1px 9px rgba(44, 13, 65, 0.1);
      border-radius: 0 10px 10px 0;
      padding: 16px 37px 16px 24px;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #868080;
      .form-label {
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 10px;
      }
      .form-control {
        height: auto;
        border: 1px solid #868080;
        border-radius: 10px;
      }
      .name, .abbreviation, .age-range-container {
        margin-right: 16px;
      }
      .name .form-control {
        min-width: 270px;
        padding: 6px 12px;
      }
      .abbreviation .form-control {
        max-width: 100px;
        padding: 6px 12px;
      }
      .age-range-container {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-flow: column nowrap;
        margin-bottom: 1.25rem;
        .form-group {
          display: flex;
          margin-bottom: 0;
        }
        .fieldset {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-flow: row nowrap;
        }
        .age-from, .age-to {
          .form-control {
            width: 60px;
            padding: 6px 12px;
            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }
        }
        .label {
          text-transform: uppercase;
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
          margin-bottom: 10px;
        }
        .ruler {
          width: 45px;
          height: 3px;
          margin: 0 13px;
          background: #EDE0F5;
          border-radius: 10px;
        }
      }
    }
  }
}
.error-tooltip {
  .arrow::before {
    border-bottom-color: rgba(255, 0, 0, 0.15);
  }
  .tooltip-inner {
    padding: 6px 12px;
    background-color: rgba(255, 0, 0, 0.15);
    border-radius: 12px;
    .invalid-feedback {
      display: flex;
      margin-top: 0;
    }
  }
}
