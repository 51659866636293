.form-toolbar {
  position: fixed;
  padding: 16px;
  left: 50%;
  transform: translateX(-50%);
  bottom: 16px;
  background: #ffffff;
  box-shadow: 0 8px 24px 0 rgba(101, 26, 152, 0.16);
  z-index: 3;

  .info-icon {
    width: 14px;
    height: 14px;
    margin-right: 12px;
  }

  .info {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.05px;
    line-height: 20px;
  }

  .btn-saved {
    background-color: rgba(248, 197, 64, 0.5);
  }

  .save-btn {
    display: flex;
    align-items: center;
  }

  .save-btn-label {
    display: block;
    visibility: hidden;
    height: 0;

    &.label-active {
      visibility: visible;
      height: auto;
    }
  }
}
