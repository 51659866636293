.app-header {
  padding: 16px 64px;
  background: #ffffff;

  .app-nav-link {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #302A2A;

    .nav-icon {
      margin-right: 7px;

      &.nav-icon-events {
        height: 12px;
        width: 18px;
      }

      &.nav-icon-question {
        width: 20px;
        height: 20px;
      }
    }
  }

  .nav-events {
    margin-right: 32px;
  }

  .nav-help {
    margin-right: 32px;
  }

  .nav-profile {
    padding: 0;
  }

  .profile-image {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    box-shadow: 0 2px 4px 0 rgba(102, 120, 138, 0.16);
    background: url("./profilePicturePlaceholder.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .auth-buttons {
    display: flex;
    align-items: center;

    .nav-btn {
      width: 88px;
    }
  }
}
