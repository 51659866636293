.date-time-control {
  .react-datepicker-wrapper {
    display: block;
  }

  .react-datepicker {
    border: 1px solid rgba(67, 90, 111, 0.3);
    box-shadow: 0 4px 8px 0 rgba(101, 26, 152, 0.16);
  }

  .react-datepicker__time-container {
    border-left: 1px solid rgba(67, 90, 111, 0.3);
  }

  .react-datepicker-popper {
    z-index: 3;
  }

  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    border-bottom-color: #651a98;
  }

  .react-datepicker__header {
    background-color: #651a98;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    color: #ffffff;
    font: inherit;
  }

  .react-datepicker__navigation--previous {
    border-right-color: #ffffff;
  }

  .react-datepicker__navigation--next {
    border-left-color: #ffffff;
  }

  .react-datepicker__day-name,
  .react-datepicker__time-name {
    color: #ffffff;
    font: inherit;
  }

  .react-datepicker__day {
    color: inherit;
    font: inherit;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    border-radius: 0.3rem;
    background-color: #f8f7fc;
    color: #651a98;
    font-weight: bold;

    &:focus {
      border: none;
      outline: none;
    }
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: #f8f7fc;
    color: #651a98;
    font-weight: bold;

    &:focus {
      border: none;
      outline: none;
    }
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background-color: #f8f7fc;
    color: #651a98;
    font-weight: bold;
  }
}
