.event-statistics {}


.event-statistics__title {
    color: #8b8b8b;
    font-size: 0.75rem;
    font-weight: 600;
    letter-spacing: 0.33px;
    line-height: 1.333;
    margin-bottom: 4px;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 30px;
}

.event-statistics__row {
    margin-bottom: 30px;
}

.event-statistics__column {
    padding: 30px 60px;
    background-color: #f8f7fc;
    height: 100%;
}

.event-statistics__column--gender-distribution {
    padding: 30px 60px 0px;
}

.event-statistics__line {
    display: flex;
    margin-bottom: 10px;
}

.event-statistics__circle {
    display: inline-flex;
    width:  25px;
    height: 25px;
    margin-right: 10px;
    background: #C4C4C4;
    border-radius: 100%;

    &--in-progress {
        background: #1070CA;
    }

    &--finished {
        background: #00BC7C;
    }

    &--abandoned {
        background: #FF393D;
    }

    &--disqualified {
        background: #353535;
    }
}

.event-statistics__name {
    min-width: 150px;
    display: inline-flex;
}

