.profile {
  .header {
    background-color: #651a98;
    background: url("../../shared/bg-secondary.jpg");
    background-size: cover;
    background-position: center bottom;

    .title {
      color: #ffffff;
      margin-top: 32px;
    }

    .profile-tabs {
      margin-top: 40px;

      .app-tab {
        max-width: 140px;
      }
    }
  }

  .image-container {
    max-width: 104px;

    .profile-picture {
      min-height: 104px;
    }
  }

  .change-picture-button {
    color: #8b8b8b;
  }
}
